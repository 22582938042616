import React from "react";
import { Link } from "react-router-dom";

import "./styles.scss";
import scrollToTop from "@utils/scrollToTop";
import useScrollPosition from "@hooks/useScrollPosition";

import messageButton from "@assets/images/message_button.png";
import facebook from "@assets/images/facebook.svg";
import instagram from "@assets/images/instagram.svg";
import youtube from "@assets/images/youtube.svg";
import whatsappWhite from "@assets/images/whatsapp-white.svg";
// import WhatsAppButton from "@Common/WhatsAppButton";
import { socialMediaLinks } from '@consts/socialMediaLinks.js';

import LoadAppButtonOnlyMobile from "@Common/LoadAppButtonOnlyMobile";

const Footer = () => {
  const { isMiddleTopOffset } = useScrollPosition();

  const handleScrollUpBtnClick = () => {
    scrollToTop();
  };

  return (
    <footer className="footer">
      {isMiddleTopOffset && (
        <div
          className="button-scroll-up"
          onClick={() => handleScrollUpBtnClick()}
        >
          <img src={messageButton} alt="scroll-up" />
        </div>
      )}
      {/* <WhatsAppButton /> */}
      <div className="footer-block after-block container" id="footer">
        <div className="footer-block-column">
          <LoadAppButtonOnlyMobile />
          <div className="img-logotype"></div>
          <p className="description  only-desk"> © 2023 FAST FINANCE SOLUTIONS  S.A.C.
            RUC 20610415769</p>
          <p className="description only-desk">Doctor Sol, bajo la denominación social Fast Finance Solutions S.A.C, con RUC 20610415769 está registrada en el Registro de Empresas de Préstamo y/o Empeño, supervisadas por la SBS, según Resolución SBS N° 02395-2023 del 17 de julio del 2023</p>
        </div>
        <div className="footer-block-column" id="href-list">
          <div className="span-block">
            <Link to="/como-funciona"><span>¿Cómo funciona?</span></Link>
            <Link to="/main-information?page=about-company"><span>Sobre nosotros</span></Link>
            <Link to="/requests?page=report"><span>Informar un problema</span></Link>
            <Link to="/requests?page=complaint"><span>Libro de Reclamaciones</span></Link>
            <Link to={"/documents?page=politics"} ><span>Política de privacidad</span></Link>
            <Link to={"/documents?page=documents-list"} ><span>Legales</span></Link>
          </div>
          <div className="contacts-info">
            <div>
              <p className="contactos">Central Telefónica:</p>
              <a href="tel:+5116231170" className="href-mail">
                +51 1-623-1170
              </a>
              <a href="tel:+5116222021" className="href-mail">
                +51 1-622-2021
              </a>               
              <a href={`mailto:${socialMediaLinks.email}`} className="href-mail">
                {socialMediaLinks.email}
              </a>
            </div>
            <div>
              <p className="contactos">Horario de Atención de nuestra central:</p>
              <p className="time-work">
                Lunes a Viernes: 09:00 – 18:00 <br/>
                Sábados: 09:00-13:00
              </p>
            </div>
          </div>
        </div>
        <div className="footer-block-column">
          <Link to="/?section=calculator" className="footer-button">Solicita tu Crédito</Link>
          <div className="list-social only-desk" >
            <a
              href={socialMediaLinks.facebook}
              target="_blank" 
              rel="noreferrer" >
              <img alt="facebook" src={facebook} />
            </a>
            <a
              href={socialMediaLinks.instagram}
              target="_blank" 
              rel="noreferrer" >
              <img alt="instagram" src={instagram} />
            </a>
            <a
              href={socialMediaLinks.youtube}
              target="_blank" 
              rel="noreferrer" >
              <img alt="youtube" src={youtube} />
            </a>
            <a
              href={socialMediaLinks.whatsapp}
              target="_blank" 
              rel="noreferrer" >
              <img alt="whatsapp" src={whatsappWhite} />
            </a>
          </div>
          <LoadAppButtonOnlyMobile className="only-desk"/>
        </div>

        <div className="footer-block-column only-mobile">
          <div className="list-social" >
            <a href={socialMediaLinks.facebook} >
              <img alt="facebook" src={facebook} />
            </a>
            <a href={socialMediaLinks.instagram} >
              <img alt="instagram" src={instagram} />
            </a>
            <a href={socialMediaLinks.youtube} >
              <img alt="youtube" src={youtube} />
            </a>
            <a href={socialMediaLinks.whatsapp} >
              <img alt="whatsapp" src={whatsappWhite} />
            </a>
          </div>
        </div>
        <div className="footer-block-column only-mobile">
          <p className="description">© 2023 FAST FINANCE SOLUTIONS  S.A.C.
            RUC 20610415769</p>
          <p className="description">Doctor Sol, bajo la denominación social Fast Finance Solutions S.A.C, con RUC 20610415769 está registrada en el Registro de Empresas de Préstamo y/o Empeño, supervisadas por la SBS, según Resolución SBS N° 02395-2023 del 17 de julio del 2023</p>
        </div>
      </div>
    </footer>
  )
};

export default Footer;
