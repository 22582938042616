import React from "react";

import "./styles.scss";

import checkPicture from "@assets/images/check_picture.svg";
import blMan from "@assets/images/prestamo-personal-online-instante-proceso.png";
import blManWebp from "@assets/images/prestamo-personal-online-instante-proceso.webp";

const PorSection = () => {
  return (
    <div className="container-width-100 ">
      <div className="block-4" id="block-4">
        <div className="container-page">
          <div className="block-4-main-container">
            <h2 className="title-block color-white">¿Por qué DoctorSol?</h2>
            <div className="block-4-description">
              <div className="columns-flex">
                <img alt="check" className="img-point" src={checkPicture} />
                <p>Solicitud de préstamo 100% online</p>
              </div>
              <div className="columns-flex">
                <img alt="check" className="img-point" src={checkPicture} />
                <p>Aprobación en 20 minutos</p>
              </div>
              <div className="columns-flex">
                <img alt="check" className="img-point" src={checkPicture} />
                <p>Sin garantías o papeleos</p>
              </div>
              <div className="columns-flex">
                <img alt="check" className="img-point" src={checkPicture} />
                <p>Sin comisiones ocultas</p>
              </div>
            </div>
          </div>
          <picture>
            <source srcSet={blManWebp} type="image/webp"/>
            <source srcSet={blMan} type="image/png" />
            <img className="abs-posotion-img" alt="man" src={blMan} />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default PorSection;
