import React from "react";

import "./styles.scss";

const InfoSection = () => {
  return (
    <div className="container-width-100 ">
      <div className="block-6" id="block-6">
        <div className="container-page">
          <div className="block-6-main-container">
            <h2 className="title-block color-white">Ejemplo de un préstamo</h2>
            <div className="block-6-description">
              <p>
                El plazo del crédito quedará establecido conforme a lo pactado entre el cliente 
                y Doctor Sol que podrá ser de <span>62</span> días a <span>180</span> días.
              </p>
              <p>
                Consideremos un préstamo de S/<span>400.00</span>.               
              </p>
              <p>
                Después de firmar el contrato en línea usted recibe S/<span>400.00</span> en su cuenta bancaria. 
                Los S/<span>70.00</span> se utilizarán para pagar la comisión de Administración. S/<span>28.80</span> son los 
                intereses por cada <span>30</span> días de plazo del crédito.              
              </p>
              <p>
                Puedes utilizar el préstamo de <span>62</span> a <span>180</span>  días con un calendario de pagos de al menos una 
                vez al mes. La primera fecha de pago del préstamo es <span>30</span> días después de la transferencia 
                del préstamo a su cuenta bancaria. El monto de pago del préstamo es de S/<span>516.50</span> incluido 
                IGV 18%. TCEA: 461%, TCEM: 38,42%.              
              </p>                                          
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
