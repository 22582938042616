import React from "react";
import { Link } from "react-router-dom";

import { useAuthState } from '@/contexts/AuthContext';

import "./styles.scss";

const HeaderMenuBar = ({ show }) => {
  const { authZoneLink } = useAuthState();

  return (
    <>
      <div className={`menu-bar-bg ${show ? "active" : ""}`} />
      <div className={`menu-bar ${show ? "active" : ""}`}>
        <ul className="list-href">
          <li>
            <Link
              to="/main-information?page=about-company"
              className="desktop-second-menu_point"
            >
              Sobre la compañía
            </Link>
          </li>
          <li>
            <a
              href={authZoneLink}
              className="desktop-second-menu_point"
            >
              Iniciar sesión
            </a>
          </li>        
          <li>
            <Link
              to="/requests?page=report"
              className="desktop-second-menu_point"
            >
              Informar un problema
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
};

export default HeaderMenuBar;
