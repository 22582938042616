import React, { useEffect, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import "./styles.scss";
import CalculatorSection from "./sections/CalculatorSection";
import ComoUsarloSection from "./sections/ComoUsarloSection";
import ComoFuncionaSection from "./sections/ComoFuncionaSection";
import PorSection from "./sections/PorSection";
import InfoSection from "./sections/InfoSection";
import FeedbackSection from "./sections/FeedbackSection";
import NavigationSection from "./sections/NavigationSection";

const MainPage = () => {
  const calculatorRef = useRef(null);

  const [searchParams] = useSearchParams();

  const currentPageSectionParam = useMemo(() => searchParams.get("section"),[searchParams]);

  const scrollToRef = (ref) => {
    if (ref) ref.current.scrollIntoView();
  };

  useEffect(() => {
    if (currentPageSectionParam === "calculator") {
      scrollToRef(calculatorRef);
    }
  }, [currentPageSectionParam]);

  return (
    <div id="main-page">
      <NavigationSection />
      <CalculatorSection ref={calculatorRef} />
      <ComoFuncionaSection />

      <div className="container-width-100">
        <ComoUsarloSection />
        <PorSection />
        <FeedbackSection />
        <InfoSection />
      </div>
    </div>
  );
};

export default MainPage;
